@import '~in-context';
@import 'tokens';

.Landing {
  display: flex;
  flex-direction: column;
  align-items: center;

  #random-name {
    font-size: 1rem;
    font-weight: 400;
    color: $black;
    transition: color 300ms;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    line-height: 1.65rem;

    span.vin {
      font-size: 1rem;
      font-weight: 400;
      margin-left: 0.5rem;
      color: $slate-gray-9;

      @include in-context(small) {
        display: none; }

      @include in-context(xlarge) {
        display: unset; } } }

  a:hover #random-name {
    color: $dark-blue; }

  .example, .AppPromo {
    @include gutter;
    @include in-context(small) {
      width: 100%; }
    @include in-context(large) {
      width: 50%; } }

  .example a {
    text-decoration: none; }

  .info-content {
    display: flex;

    @include in-context(small only) {
      margin-top: 2rem;

      .AppPromo {
        margin-top: 2rem; } }

    @include in-context(small) {
      flex-direction: column; }

    @include in-context(medium only) {
      margin-left: $gutter-width;
      margin-right: $gutter-width; }

    @include in-context(medium) {
      margin-top: 2rem; }

    @include in-context(896px) {
      flex-direction: row; }

    @include in-context(large only) {
      margin-left: $gutter-width / 2;
      margin-right: $gutter-width / 2; }

    @include in-context(xlarge) {
      max-width: 72rem;
      margin: {
        top: 3rem;
        left: $gutter-width;
        right: $gutter-width; } }


    img.store-image {
      width: 124px;
      margin-top: 0.25rem;

      &:first-child {
        margin-right: 1rem; } } }

  .history-content {
    width: 100%;
    padding: {
      left: $gutter-width / 2;
      right: $gutter-width / 2; }

    @include in-context(medium) {
      padding: {
        left: $gutter-width * 1.5;
        right: $gutter-width * 1.5; } }

    @include in-context(896px) {
      padding: {
        left: $gutter-width * 3;
        right: $gutter-width * 3; } }

    @include in-context(large) {
      display: flex;
      width: 100%;
      justify-content: center;

      .SearchHistory {
        width: 100%;
        max-width: 56rem; }

      .last-ten-list {
        display: flex;
        justify-content: space-between; }

      .first-five, .last-five {
        width: calc(50% - #{$gutter-width / 2}); }
      // .first-five
      //   margin-right: $gutter-width / 2

      // .last-five
 }      //   margin-left: $gutter-width / 2

    // width: 100%
    // margin:
    //   top: 2rem
    //   left: $gutter-width / 2
    //   right: $gutter-width / 2


    // +in-context(medium only)
    //   margin:
    //     left: $gutter-width
    //     right: $gutter-width

    // +in-context(large)
    //   margin:
    //     top: 3rem
    //     left: calc(#{$col-width} * 2 + #{$gutter-width})
    //     right: calc(#{$col-width} * 2 + #{$gutter-width})

    // .last-ten-list
    //   display: flex

    //   +in-context(small)
    //     flex-direction: column

    //   +in-context(medium only)
    //     .first-five, .last-five
    //       width: 100%

    //   +in-context(large)
    //     flex-direction: row

    //     .first-five, .last-five
    //       width: calc(50% - #{$gutter-width / 2})

    //     .first-five
    //       margin-right: $gutter-width / 2

    //     .last-five
 } }    //       margin-left: $gutter-width / 2
