@import 'tokens';
// @import '~in-context'

.Steps {
  display: flex;
  // justify-content: space-between
  // max-width: 12rem

  & > span {
    color: $slate-gray-9;
    display: flex;
    align-items: center;
    padding-right: 2.5rem; }

  & > span.done {}

  & > span.active {
    color: $dark-blue;
    font-weight: bold; }

  span span.number {
    display: flex;
    width: 2rem;
    height: 2rem;
    border-radius: 100px;
    border: 1px solid $slate-gray-5;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem; } }

.Form {
  display: flex;
  align-items: flex-start;

  * {
    box-sizing: border-box; }

  fieldset {
    border: 0;
    padding: 0;
    margin: 0; }

  .errors {
    margin-top: 1rem;
    color: $slate-gray-9;
    color: $black;
    color: $danger;
    // color: mix($danger, $dark-blue, 75%)
    font-weight: 600;
    font-size: 14px/16px * 1rem; } }
