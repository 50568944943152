@import '~in-context';
@import 'tokens';

.Footer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: $slate-gray-9;
  font-variant: small-caps;

  @include in-context(small only) {
    margin-top: 1rem;
    padding: {
      top: 1rem;
      bottom: 1rem; } }

  @include in-context(medium) {
    margin-top: 2rem;
    padding: {
      top: 3rem;
      bottom: 3rem; } }


  span.year {
    font-size: 80%; }

  a, a:link, a:hover, a:active, a:visited {
    text-decoration: none;
    color: $slate-gray-6;
    font-weight: bold; }

  a:hover {
    color: $dark-blue; }


  & > span {
    display: flex;
    flex-direction: column;
    margin: {
      left: 2rem;
      right: 2rem; }

    & > span {
      display: block; } }

  @include in-context(small only) {
    flex-direction: column;

    & > span {
      flex-direction: row;

      span, a {
        margin-right: 1rem; } } }

  a {
    padding: {
      top: 0.5rem;
      bottom: 0.5rem; } } }
