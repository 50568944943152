@import 'tokens';
// @import '~in-context'

input, textarea {
  &.FormInput {
    display: flex;
    @include font-size-2;
    line-height: 2.5rem;
    padding: {
      left: 0.5rem;
      right: 0.5rem; }
    // border: 1px solid $slate-gray-2
    // border: 1px solid transparent
    @include inner-shadow-1;
    border: 1px solid #EDF0F4;
    background: #fbfcfd;
    background: $slate-gray-1;
    color: black;
    border-radius: 3px;
    transition: border-color 300ms;

    &:hover, &:focus {
      border: 1px solid $slate-gray-4;
      outline: none; }

    &::placeholder {
      color: $slate-gray-9; } } }

input.FormInput {
  height: 2.5rem;
  padding: {
    top: 0.5rem;
    bottom: 0.5rem; } }

.FormLabel {
  display: inline-block;
  width: 12rem;
  color: $table-th-slate;
 }  // text-align: right
