@import '~in-context';

* {
  box-sizing: border-box; }

html {
  @include in-context(small only) {
    font-size: 13px; }
  @include in-context(medium only) {
    font-size: 14px; }
  @include in-context(large only) {
    font-size: 15px; }
  @include in-context(xlarge) {
    font-size: 16px; } }

body, input, textarea, button {
  font-family: 'Proxima Nova', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.01em; }

body {
  margin: 0;
  color: #333; }

a, path {
  transition: color 300ms, fill 300ms; }
