@import 'tokens';
@import '~in-context';

.CookieConsent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: $dark-blue;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  font-size: 0.875rem;
  line-height: 140%;
  z-index: 3;

  @include in-context(small only) {
    flex-direction: column;
    padding: 0.25rem 1rem 0.5rem 1rem;

    p {
      text-align: center;
      margin-bottom: 1.5rem; }

    Button {
      margin-bottom: 1rem; } }

  & p {
    color: white !important;
    max-width: 36rem;
    margin-right: 1rem; }

  a, a:link, a:active, a:visited, a:hover {
    color: white; }
  a:hover {
    text-decoration: none; }

  .Button {
    font-size: 0.875rem;
    min-width: 10rem;
    cursor: pointer; } }
