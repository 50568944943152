@import '~in-context';
@import 'tokens';

.Legal {
  background-color: $slate-gray-1;
  @include in-context(medium) {
    padding-bottom: 3rem; }

  .legal-content {
    margin: {
      top: 2rem; }

    @include in-context(large) {
      margin: {
        top: 3rem;
        left: calc(#{$col-width} * 2 + #{$gutter-width});
        right: calc(#{$col-width} * 2 + #{$gutter-width}); } }

    h1, h2, h3 {
      color: #92A6B9;
      font-weight: 600; }

    h2 {
      padding-bottom: 1rem;
      border-bottom: 2px solid $slate-gray-2; }

    h1, h2, h3, p {

      @include in-context(small only) {
 }        // flex-direction: column

      @include in-context(medium) {
        width: calc(75% - #{$gutter-width / 2});
        margin-right: auto;
        margin-left: auto; } }

    a, a:link, a:visited, a:hover, a:active {
      color: $dark-blue;
      text-decoration: none;
      font-weight: 500; }

    a:hover {
      color: $black; } } }
