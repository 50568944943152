@import '~in-context';
@import 'tokens';

.DetailActions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 32rem;
  padding: {
    top: 2rem;
    left: $gutter-width / 2;
    right: $gutter-width / 2; }

  @include in-context(small only) {
    display: flex; }

  @include in-context(large) {
    padding-bottom: 1rem; }

  @include in-context(xlarge) {
    display: none; }

  .Button {
    width: calc(50% - #{$gutter-width / 8});
    max-width: 20rem; } }
