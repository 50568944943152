@import "tokens";

.Logo {
  width: 100%;
  height: auto;
  cursor: pointer;

  // path
 }  //   fill: $dark-blue

a:hover {
  .Logo path {
    fill: $black; } }
