@import '~in-context';
@import 'tokens';

@keyframes appear {
    from {
      opacity: 0;
      transform: translateY(-5px); }
    to {
      opacity: 1;
      transform: translateY(0px); } }

.SearchField {
  display: flex;

  @include in-context(small only) {
    width: 100%;
    padding: {
      left: 1rem;
      right: 1rem; } }

  @include in-context(medium) {
    width: 27rem; }

  @include in-context(704px) {
    width: 30rem; }

  @include in-context(768px) {
    width: 30rem;

    @include in-context(xlarge);
    width: 35rem; }

  input {
    @include font-size-2;
    line-height: 3rem;
    border: none; }

  .input-with-icon {
    position: relative;
    flex-grow: 1; }

  input[type=text] {
    -webkit-appearance: none;
    @include inner-shadow-1;
    width: 100%;
    color: $gray-1;
    padding-left: 1.5rem;
    border: 1px solid #F6F7F9;
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
    transition: border-color 300ms;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    height: 43px;
    line-height: 43px;
    border-right-width: none;
    margin: {
      left: 0;
      right: 0; }

    @include in-context(small) {
      letter-spacing: 0.09em; }

    @include in-context(704px) {
      letter-spacing: 0.2em; }

    &:hover, &:focus {
      outline: none;
      border: 1px solid $slate-gray-4;
      border-right-width: none; }

    &::placeholder {
      color: $slate-gray-9;
      text-transform: none;
      letter-spacing: 0.01em; } }

  a.search-history {
    position: absolute;
    top: 50%;
    right: 0.75rem;
    transform: translateY(calc(-50% + 1px));

    svg path {
      fill: $slate-gray-9; } }

  &.isHistoryVisible a.search-history, a.search-history:hover {
    svg path {
      fill: $dark-blue; } }

  input[type=submit] {
    position: absolute;
    pointer-events: none;
    opacity: 0; }

  a.submit {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 43px;
    @include font-size-2;
    font-weight: bold;
    text-transform: lowercase;
    font-variant: small-caps;
    text-align: center;
    text-decoration: none;
    background: $slate-gray-4;
    color: $dark-blue;
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    transition: background-color 300ms;
    cursor: pointer;

    @include in-context(small only) {
      width: 5rem;

      span {
        display: none; } }

    @include in-context(medium) {
      width: 9rem;

      svg {
        display: none; } }

    &:focus {
      outline: none; }

    &:hover, &:focus {
      background: $slate-gray-5;
      color: $black; }

    svg {
      path {
        fill: $dark-blue; } } } }

.PersonalHistory {
  animation: appear 100ms cubic-bezier(0,0,0,1) backwards;
  position: absolute;
  @include shadow-2;
  background: white;
  border-radius: 10px;
  z-index: 3;
  overflow: hidden;

  @include in-context(small only) {
    left: 1rem;
    right: 1rem; }

  @include in-context(medium) {
    left: calc((100vw / 2) - (33.5rem / 2));
    width: 33.5rem; }

  .entry {
    cursor: pointer;
    position: relative;
    border-top: 1px solid $slate-gray-3;
    transition: background 300ms, color 300ms;
    padding: 0.75rem {
      right: 2rem; }

    span {
      transition: color 300ms;
      display: block;
      text-align: left;
      line-height: 1.25rem; }

    span.vin {
      color: $slate-gray-9;
      display: flex;
      align-items: center;

      svg {
        margin-left: 0.125rem;
        height: 1rem; }

      svg.danger {
        path {
          fill: $danger; } }

      svg.warning {
        path {
          fill: $warning; } }

      svg.safe {
        path {
          fill: $slate-gray-6; } } }

    svg.chevron {
      position: absolute;
      top: 50%;
      right: 0.75rem;
      transform: translateY(-50%);

      path {
        fill: $dark-blue; } } }

  .entry:first-child {
    border-top: 0; }

  .entry:hover {
    background: $slate-gray-1;
    color: $dark-blue;

    span.vin {
      color: $black; }

    &, & span.vin {
      svg, svg.anomaly, svg.safe {
        path {
          fill: $black; } } } } }

.isTall .PersonalHistory {

  @include in-context(small only) {
    top: 10.5rem; }

  @include in-context(medium) {
    top: 9.75rem; } }

.isDetail .PersonalHistory {
  @include in-context(small only) {
    top: 10.5rem; }

  @include in-context(medium) {
    top: 4.5rem; } }
