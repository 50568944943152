$og-dark-blue: #2B4F7C;
$hy-dark-blue: #0659C0;
$ap-dark-blue: #0F69C9;
$dark-blue: mix($og-dark-blue, $ap-dark-blue, 60%);
$slate-gray-1: #F7F9FA;
$slate-gray-3: #EAEFF6;
$slate-gray-2: mix($slate-gray-1, $slate-gray-3);
$slate-gray-4: #DFE7F2;
$slate-gray-6: #BFCCD9;
$slate-gray-5: mix($slate-gray-4, $slate-gray-6);
$slate-gray-9: #92A6B9;
$gray-1: #333333;
$black: #000;
$danger: #EB5757;
$warning: #ffab00;
$notice: #03E1FF;
$yellow: #FED130;
$amzs: #02224C;
$table-th-slate: mix($slate-gray-9, black, 90%);

$gutter-width: 4rem;
$col-width: "((100vw - 13 * #{$gutter-width}) / 12)";

@mixin gutter {
  @include in-context(small only) {
    padding: {
      left: $gutter-width / 2;
      right: $gutter-width / 2; } }

  @include in-context(medium) {
    padding: {
      left: $gutter-width / 2;
      right: $gutter-width / 2; } } }

@mixin small-caps {
  font-variant: small-caps; }

@mixin font-size-2 {
  font-size: 18px/16px * 1rem; }

@mixin font-size-3 {
  font-size: 22px/16px * 1rem; }

@mixin inner-shadow-1 {
  box-shadow: inset 0px 1px 6px rgba(43, 79, 124, 0.08) !important; }

@mixin shadow-1 {
  box-shadow: 0px 4px 23px rgba(43, 79, 124, 0.10); }

@mixin shadow-2 {
  box-shadow: 0px 4px 23px rgba(43, 79, 124, 0.17); }
