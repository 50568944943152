@import '~in-context';
@import 'tokens';
@import 'toastify';

.App {
  display: flex;
  flex-direction: column; }


.grow {
  flex-grow: 1; }

.only-large {
  @include in-context(small only) {
    display: none; }
  @include in-context(medium only) {
    display: none; } }

.row {
  padding: {
    left: $gutter-width / 2;
    right: $gutter-width / 2; } }

.full, .half, .third, .quarter, .five-of-twelve {
  @include in-context(medium) {
    padding: {
      left: $gutter-width / 2;
      right: $gutter-width / 2; } } }

.row {
  display: flex;

  @include in-context(small only) {
    flex-direction: column; } }

.full {
  width: 100%; }

.half {
  width: 50%;
  @include in-context(small only) {
    width: 100%; } }

// .third
//   width: 33%
//   +in-context(small only)
//     width: 100%

// .five-of-twelve
//   width: 100/12*5 * 1%

//   +in-context(small only)
//     width: 100%

h2 {
  font-weight: normal; }

h3, h4 {
  margin: {
    top: 1rem;
    bottom: 1rem; } }

h3 {
  @include font-size-2;
  color: $slate-gray-9;
  line-height: 1.5rem;
  padding-bottom: 1rem;
  font-variant: normal;
  font-weight: bold;
  border-bottom: 2px solid $slate-gray-2; }

h4.caps {
  @include font-size-2;
  color: $slate-gray-9;
  line-height: 1.5rem;
  font-weight: bold;
  font-variant: small-caps; }

p {
  color: $gray-1;
  line-height: 1.5rem; }

img {
  width: 100%; }

table {
  width: 100%;
  text-align: left;
  border-spacing: 0;

  th {
    font-weight: normal;
    color: $table-th-slate;
    font-weight: 400; }

  td {
    font-weight: 500;
 } }    // color: black

.Toastify__toast {
  padding: 0.4rem 1rem 0.5rem 1rem; }
