@import "tokens";
@import "~in-context";

.AppPromo {
  // svg path
  //     fill: $dark-blue

  .media-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;

    p {
      margin: 0; }

    svg {
      width: 6rem;
      height: auto;
      margin-right: 1rem;
      // margin:
      //   top: 1rem
      //   right: 1rem

      @include in-context(small only) {
        width: 14rem; } } }
  h4.caps {
    margin-bottom: 0; } }
