@import '~in-context';
@import 'tokens';

.NotFound {
  .not-found-content {
    margin: {
      top: 1rem;
      bottom: 1rem; }

    @include in-context(large) {
      margin: {
        top: 3rem;
        left: calc(#{$col-width} * 2 + #{$gutter-width});
        right: calc(#{$col-width} * 2 + #{$gutter-width}); } }

    h1, h2 {
      color: #92A6B9;
      font-weight: 600;
      padding-bottom: 1rem;
      border-bottom: 2px solid $slate-gray-2; }


    h1, h2, p, ul {

      @include in-context(small only) {
 }        // flex-direction: column

      @include in-context(medium) {
        width: calc(75% - #{$gutter-width / 2});
        margin-right: auto;
        margin-left: auto; } }

    ul {
      // list-style: none
 }      // padding-left: 0

    li {
      margin: {
        top: 0.5rem;
        bottom: 0.5rem; }

      strong {
        min-width: 6rem; } } } }
