@import '~in-context';
@import 'tokens';

.About {

  ul {
    list-style: none;
    padding-left: 0; }

  li {
    display: flex;
    margin: {
      top: 0.5rem;
      bottom: 0.5rem; }

    strong {
      min-width: 6rem; } } }
