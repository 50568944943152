@import 'tokens';
@import '~in-context';

.Contract {

  @include in-context(small) {
    .Steps {
      span:not(.active) {
        .label {
          display: none; } } } }
  @include in-context(large) {
    .Steps {
      span:not(.active) {
        .label {
          display: unset; } } } }

  .Form {
    flex-direction: column;

    p.in-form-notice {
      margin-top: 0;
      margin-bottom: 2rem; }

    fieldset {
      width: 100%;
      margin-bottom: 0.75rem; }

    .errors {
      margin: {
        top: -1rem;
        bottom: 1rem; }

      @include in-context(medium) {
        padding-left: 12.6rem; } } }

  .FormField {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    @include in-context(small only) {
      flex-direction: column;
      align-items: flex-start; } }

  .FormLabel {
    width: 12rem; }

  input.FormInput {
    @include in-context(small only) {
      width: 100%;
      margin-top: 0.5rem; }

    @include in-context(medium) {
      width: calc(100% - 12rem); } }

  textarea.FormInput {
    width: calc(100%); }


  .Submit {
    width: 12rem;
    margin-top: 1rem;
    @include in-context(medium) {
      margin-left: 12rem; } } }
