@import 'tokens';
@import '~in-context';

a.Submit {
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  @include font-size-2;
  height: 2.5rem;
  line-height: 2.5rem;
  background: $slate-gray-4;
  color: $dark-blue;
  font-weight: bold;
  text-transform: lowercase;
  font-variant: small-caps;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  transition: color 300ms, background 300ms;

  &:hover {
    font-weight: bold;
    background: $slate-gray-5;
    color: $black; }

  &:focus {
    outline: none; }

  &.isDisabled {
    opacity: 0.5; } }
