@import '~in-context';
@import 'tokens';

$sidebar-width: 18rem;

@include in-context(xlarge) {
  .Detail {

    .name.full {
      position: sticky;
      top: 5rem;
      background: white;
      z-index: 4; }

    .pane h3.title {
      position: sticky;
      top: 11rem;
      background: white;
      z-index: 3; }

    ul.toc {
      position: sticky;
      top: calc(11rem - 2px); }

    .toc-anchor {
      position: absolute;
      margin-top: calc(-11rem + 1px); }

    #podatki.toc-anchor {
      margin-top: calc(-11rem + 4px); }

    .year-anchor {
      position: absolute;
      margin-top: calc(-15rem - 1px); } } }

.Detail {
  .charts, .stats-1, .stats-2 {
    h3 {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
      line-height: 1.5rem; }

    h3.danger {
      color: $danger; } }

  .charts, .stats-2 {
    h3 {
      margin-bottom: 1rem;
      @include in-context(small only) {
        margin-top: 2rem; }
      @include in-context(medium) {
        margin-top: 1.33rem; } } }

  .name {
    h2 {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 2px solid $slate-gray-2;
      padding-bottom: calc(1rem - 2px);
      margin-top: 0;
      padding-top: 1rem;
      margin-bottom: 0;
      font-weight: 500;
      @include in-context(small only) {
        margin-top: 1rem;
        @include font-size-2; }



      &.is_stolen {
        border-bottom: 2px solid $danger; } }

    .label {
      display: flex;
      align-items: center;
      margin-right: 0.5rem; }

    img {
      width: auto;
      height: 4rem;
      margin-right: 1rem;

      @include in-context(small only) {
        width: 2rem;
        height: 2rem; } }

    .badge-with-tooltip {
      position: relative;
      display: flex;
      align-items: center;
      margin-left: 1.5rem;
      color: $danger;
      font-size: 13px/16px * 1rem;

      span {
        display: none; }

      svg {
        margin-right: 0.5rem; }

      &:after {
        text-align: center;
        top: 2rem;
        left: 0.75rem;
        transform: translateX(-50%);
        padding: 0.5rem;
        display: block;
        position: absolute;
        border-radius: 3px;
        opacity: 0;
        pointer-events: none;
        transition: opacity 200ms;
        min-width: 9rem;
        color: white;
        z-index: 10; } // hover tooltip above other positioned elements

      &:hover:after {
        opacity: 1; }

      &.danger {
        path {
          fill: $danger; }

        &:after {
          background: $danger; } }

      &.warning {
        path {
          fill: $warning; }

        &:after {
          background: $warning; } }

      &.notice {
        path {
          fill: $notice; }

        &:after {
          background: $notice; } }

      &.stolen:after {
        min-width: 7rem;
        content: 'Vozilo je v bazi ukradenih vozil.'; }

      &.anomalous:after {
        content: 'Prevrten števec prevoženih kilometrov.'; }

      &.tehnicni:after {
        content: 'Zgodovina tehničnih pregledov ni brezhibna.'; }

      &.corrected:after {
        content: 'Vnos kilometrov je bil popravljen po uradnem postopku.'; } }

    a.print {
      display: flex;
      justify-content: center;
      // position: absolute
      // right: 0
      // // top: calc(50% - 0.5rem)
      // top: 50%
      // transform: translateY(-50%)
      font-variant: small-caps;
      font-weight: bold;
      line-height: 1.5rem;
      @include font-size-2;

      @include in-context(small only) {
        span {
          display: none; } }

      svg {
        margin-right: 0.5rem; }
      path {
        fill: $dark-blue; } }

    a, a:link, a:hover, a:active, a:visited {
      text-decoration: none;
      color: $dark-blue; }

    a:hover, a:hover svg path {
      color: $black;
      fill: $black; } }

  .Stolen {
    @include gutter;
    @include in-context(small only) {
      padding: {
        left: $gutter-width / 2;
        right: $gutter-width / 2; } }

    h3 {
      border-bottom: none;
      margin-top: -2px;
      margin-bottom: 0;
      padding-top: 1rem;
      padding-bottom: 0;
      color: $danger;
      border-top: 2px solid $danger; }

    p.description {
      border-bottom: 2px solid $danger;
      padding-bottom: 0.5rem;
      margin-top: 0.5rem;
      margin-bottom: 0;

      a {
        text-decoration: none;
        color: $danger;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 13px/16px * 1rem;
        display: flex;
        align-items: center;
        padding: {
          top: 0.5rem;
          bottom: 0.5rem; } }

      a:hover {
        color: $dark-blue; } } }
  h3 {
    position: relative;
    border-bottom: none;
    margin-bottom: 0;
    @include in-context(small only) {
      margin-top: 2rem; } }


  ul.toc {
    list-style: none;
    padding-left: 0;
    margin-top: 0rem;
    @include font-size-2;
    letter-spacing: 0.01em;
    // border-top: 2px solid $slate-gray-2
    padding-top: 1rem;

    a, a:link, a:visited {
      display: block;
      color: $slate-gray-9;
      text-decoration: none;
      font-weight: bold;
      padding: {
        top: 0.75rem;
        bottom: 0.75rem; } }

    span {
      display: flex;
      flex-wrap: wrap;
      max-width: 20rem;

      a {
        font-weight: normal !important;
        margin-right: 1rem; } }

    a:hover, a:active, a.active {
      color: $dark-blue; } }

  .accordion {
    @include in-context(small only) {
      margin-top: 2rem;
      border-top: 2px solid $slate-gray-2; }

    @include in-context(small) {
      width: 100%; }

    @include in-context(xlarge) {
      width: calc(100% - #{$sidebar-width}); }

    h3 {
      height: 3rem;
      display: flex;
      align-items: center;
      margin: {
        top: 0;
        bottom: 0; }
      padding: {
        top: 2rem;
        bottom: 2rem; }
      border-bottom: 2px solid $slate-gray-2;
      color: $dark-blue;
      font-variant: small-caps;

      svg.icon {
        margin-right: 0.5rem;

        path {
          fill: $dark-blue; } }

      svg.warning {
        margin-right: 0.5rem;

        path {
          fill: $warning; } }


      svg.chevron {
        margin-right: 0.5rem;
        // position: absolute
        // right: 0
        // top: calc(50%)
        // transform: translateY(-50%)

        path {
          fill: $dark-blue; } } }

    h3.active {
      border-bottom-width: 1px; }

    table {
      border-bottom: 2px solid $slate-gray-3;
      margin-top: 1rem;

      // tr:nth-child(odd)
      //   color: mix($slate-gray-3, #333)
      tr:nth-child(even) {
        background: mix($slate-gray-1, white); }

      th {
        padding-right: 1rem; }

      th ,td {
        letter-spacing: 0.01em; }

      td.warning {
        color: mix(red, yellow, 33%);
        text-transform: uppercase;
        font-weight: 600; } }

    .pane {
      cursor: pointer;
      transition: background-color 300ms; }

    .pane .title:hover {
      background-color: $slate-gray-1; }

    .pane .title {
      &, & svg path {
        transition: color 300ms, fill 300ms, background-color 300ms; } }

    .pane .title:hover {
      &, & svg path {
        color: $black;
        fill: $black; } } }

  #zgodovina-vozila table {
    @include in-context(xlarge) {
      th {
        width: 21rem; } } }

  #tabs-swipe-zgodovina {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0;
    padding: {
      left: 0;
      top: 1rem;
      right: 0;
      bottom: 1rem; }

    li {
      margin-right: 2rem;

      a {
        cursor: pointer;
        font-weight: bold;
        text-transform: uppercase;
        color: $slate-gray-5;
        text-decoration: none; }

      a.active, a:hover {
        color: $dark-blue; } } }

  #zgodovina-vozila {
    h4 {
      margin-top: 2rem; } }

  table {
    padding-bottom: 1.5rem;

    td, th {
      line-height: 1.5;
      padding: {
        top: 0.5rem;
        bottom: 0.5rem; } } }

  .stats-1 {
    @include in-context(small only) {
      display: none; }
    @include in-context(xlarge) {
      display: block; }

    h3 {
      padding-bottom: 0;
      margin-bottom: 0.5rem; }

    th {
      font-weight: normal;
      margin-bottom: 0; }

    td {
      font-weight: normal;
      margin-top: 0.5rem;
      color: $slate-gray-9; } }

  .stats-2 {
    @include gutter;

    @include in-context(medium) {
      display: none; }

    @include in-context(xlarge) {
      display: unset;
      width: 100/12*2.5 * 1%; } }

  .detail-content {
    width: 100%;

    @include in-context(xlarge) {
      width: calc(100% - #{$sidebar-width});
      padding: {
        left: $gutter-width / 2;
        right: $gutter-width / 2; } } }

  .content-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @include in-context(xlarge) {
      flex-direction: row; } }

  #sidebar-root {
    @include in-context(small) {
      width: 100%; }
    @include in-context(xlarge) {
      position: sticky;
      top: 5rem;
      width: $sidebar-width; } }

  .detail-sidebar {
    background: $slate-gray-1;
    background: linear-gradient(to bottom, $slate-gray-1, white);
    padding: {
      top: 1.25rem;
      bottom: 1rem;
      left: $gutter-width / 2;
      right: $gutter-width / 2; }

    @include in-context(medium) {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .pane {
        width: calc(50% - #{$gutter-width / 2}); }
      p {
        max-width: 20rem; } }

    @include in-context(xlarge) {
      display: flex;
      flex-direction: column;

      .pane {
        width: unset; }

      p {
        max-width: unset; } }

    h3 {
      margin-bottom: 0;
      padding-bottom: 0;
      display: flex;
      align-items: center;

      svg.safe {
        margin-left: 0.5rem;

        path {
          fill: $slate-gray-9; } } }

    p {
      font-size: 1rem; }

    .Button {
      background: $slate-gray-4;
      color: $dark-blue;
      width: 12rem;
      border-radius: 3px;
      font-size: 14px;
      margin: {
        top: 1.5rem;
        bottom: 2rem; }

      @include in-context(small only) {
        width: 14rem; }

      &:hover {
        background-color: $slate-gray-5;
        color: $black; } } }

  .name {
    @include in-context(small) {
      padding: {
        left: 2rem;
        right: 2rem; } } }

  .car-data {
    display: flex;
    @include gutter;

    @include in-context(small) {
      padding: {
        left: 2rem;
        right: 2rem; } }


    .toc-container {
      display: none; }

    @include in-context(xlarge) {
      .toc-container {
        display: unset;
        width: $sidebar-width; }

      .toc {
        width: $sidebar-width; } }

    // +in-context(medium)
    //   width: 60%

    // +in-context(xlarge)
 }    //   width: 100/12*8 * 1%
  .charts {
    // +gutter
    padding-bottom: 1rem;
    margin: {
      left: $gutter-width / 2;
      right: $gutter-width / 2; }


    @include in-context(large) {
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid $slate-gray-2;

      .chart-container {
        width: calc(50% - #{$gutter-width / 4}); } }

    // +in-context(medium)
    //   width: 40%

    // +in-context(xlarge)
 } }    //   width: 100/12*4 * 1%
