@import '~in-context';
@import 'tokens';

#header-root.isDetail {
  position: relative;

  @include in-context(xlarge) {
    position: sticky;
    top: 0;
    z-index: 5;
    background: white; } }

.Header {
  width: 100%;
  display: flex;
  align-items: center;

  @include shadow-1;

  &.isSlim {
    justify-content: center;
    align-items: center;

    @include in-context(small only) {
      flex-direction: column;
      justify-content: flex-start;
      height: 11.5rem; }

    @include in-context(medium) {
      height: 5rem;

      a[href='/'] {
        position: absolute;
        left: $gutter-width / 2;
        top: 50%;
        transform: translateY(-50%); }

      // svg.Logo
      //// margin-left: $gutter-width
      //// margin-right: $gutter-width

      .SearchField {
        transform: translateY(-1px); } }

    @include in-context(xlarge) {
      a[href='/'] {
        left: $gutter-width; } } }

  svg.Logo {
    display: inline-block;
    margin: {
      top: 2rem;
      bottom: 2rem; }

    @include in-context(small) {
      // line-height: 1.75rem
      // height: 1.75rem
      max-width: 9rem;
      width: unset; }

    @include in-context(768px) {
      line-height: 2rem;
      // height: 2rem
      width: 100%;
      max-width: 12rem; } }


  .SearchField {
    @include in-context(medium only) {
      margin-left: 3rem; } }

  .menu {

    @include in-context(small) {
      position: absolute;
      top: 50%;
      right: $gutter-width / 2;
      transform: translateY(calc(-50% - 2px)); }

    @include in-context(small only) {
      top: 3.25rem;
      .Avatar {
        display: none; } }

    @include in-context(xlarge) {
      right: $gutter-width; }


    a, a:link, a:hover, a:active, a:visited {
      text-decoration: none;
      color: $slate-gray-6; }

    a:hover, a:active, a.active {
      color: $dark-blue; }

    a {
      margin-left: 1.5rem;
      @include font-size-2;
      line-height: 2rem;
      font-weight: bold;
      font-variant: small-caps;
      text-transform: lowercase; }

    a.my-garage {
      display: flex;
      align-items: center;

      @include in-context(small) {
        .prefix {
          display: none; } }
      @include in-context(large) {
        .prefix {
          display: unset; } }

      &:hover .Avatar {
        background: $dark-blue; } } }

  &.isTall {
    height: 12rem;

    .menu {
      top: 3.25rem; } }

  &.isCentered {
    flex-direction: column; } }

.Avatar {
  font-weight: 300;
  margin-left: 1rem;
  text-align: center;
  background: $slate-gray-5;
  color: white;
  line-height: 2.25rem;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 2.25rem;
  transition: background-color 300ms; }
