@import 'tokens';
@import '~in-context';


.LoginForm {
  display: flex;
  align-items: flex-start;
  margin-top: 2rem;

  input#phoneNumber, input#code {
    letter-spacing: 0.2em;

    &::placeholder {
      letter-spacing: 0.01em; } }

  &.Profile {
    flex-direction: column;

    div.name, div.email {
      width: 100%;
      display: flex;
      margin-bottom: 1rem;

      @include in-context(small only) {
        flex-direction: column; }

      fieldset {
        margin-right: 1rem; } }

    div.name {
      @include in-context(small only) {
        margin-bottom: 0; } }

    input {
      min-width: 18rem !important; }

    .Submit {
      width: 13rem;
      margin-left: 0;

      @include in-context(small only) {
        margin-top: 1rem; } } }

  .Submit {
    width: 10rem;
    margin-left: 1rem; }

  .Submit:focus {
    outline: none;
    user-select: none; }

  .FormInput {
    @include in-context(500px) {
      min-width: 15rem; } } }
