.MyGarage {
  .full {
    min-height: 23.875rem; }

  h3 {
    margin-top: 0; }

  .entries {
    margin: {
      top: 1.5rem;
      bottom: 2rem; }

    .CarButton:last-of-type {
      border-bottom: 0; } }

  .actions {
    display: flex;
    align-items: center; }

  .actions > * {
    margin-right: 1rem; } }
