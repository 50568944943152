@import 'tokens';
@import '~in-context';

.Button {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border: none;
  height: 2.5rem;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  font-family: Proxima Nova, sans-serif;
  transition: background 300ms, color 300ms;
  background: $slate-gray-3;
  color: $dark-blue;

  &:focus {
    outline: none; }

  &:hover {
    background: $slate-gray-4;
    color: $black; }

  &:disabled {
    opacity: 0.5; }

  &.isDanger {
    background: transparent;
    color: $danger;

    svg path {
      fill: $dark-blue; }

    &:hover {
      background: transparent;

      svg path {
        fill: $danger; } } }

  &.isInverse {
    background: white;
    color: $dark-blue;

    &:hover {
      background: $slate-gray-2;
      color: $black; } } }
