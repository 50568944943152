@import "tokens";

.CarButton {
  position: relative;
  border-bottom: 1px solid $slate-gray-3;
  cursor: pointer;
  transition: background-color 300ms, border-color 300ms;
  border: {
    left: 1rem solid transparent;
    right: 1rem solid transparent; }
  margin: {
    left: -1rem;
    right: -1rem; }

  &:hover {
    background: $slate-gray-1;
    border: {
      left: 1rem solid $slate-gray-1;
      right: 1rem solid $slate-gray-1; }
    margin: {
      left: -1rem;
      right: -1rem; }
 }    // background: linear-gradient(to right, white 0%, $slate-gray-1 50%, white 100%)

  a {
    display: block;
    padding: {
      top: 1rem;
      bottom: 1rem; } }

  a, a:link, a:active, a:visited {
    text-decoration: none;

    .make_and_model {
      color: $gray-1;
      margin-bottom: 0.5rem; } }

  .details {
    display: flex;
    font-size: 13px/16px * 1rem;
    line-height: 24px/16px * 1rem;

    svg {
      margin-right: 0.25rem; } }

  path {
    fill: $dark-blue; }

  .detail {
    display: flex;
    margin-right: 0.75rem;
    line-height: 24px/16px * 1rem;
    color: $slate-gray-9;
    align-items: center; }

  .vehicle {
    margin-right: 0.25rem; }

  .anomaly {
    color: $danger;
    path {
      fill: $danger; } }

  .warning {
    color: $warning;
    path {
      fill: $warning; } }

  .chevron-right {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%); }


  .make_and_model {
    transition: color 300ms; }

  .detail {
    transition: color 300ms; }

  path {
    transition: fill 300ms; }

  &:hover {
    a {
      .make_and_model {
        color: $dark-blue; }

      .details {

        .detail, .detail.anomaly {
          color: $black; } } }

    path {
      fill: $black; } }


  &.hasChildren {

    a {
      display: flex;
      flex-direction: row; }

    .car-data {
      flex-grow: 1; }

    .children {
      padding-right: 2.5rem;
      display: flex;
      align-items: center; }

    .Button {
      margin-left: 1rem; } } }
