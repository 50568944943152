@import '~in-context';
@import 'tokens';

.SimplePage {
  @include in-context(medium) {
    min-height: calc(100vh - 5rem - 12rem);
    display: flex;
    flex-direction: column;
    justify-content: center; }

  .row {
    margin: {
      top: 2rem; }

    @include in-context(large) {
      margin: {
        top: 3rem;
        left: calc(#{$col-width} * 2 + #{$gutter-width});
        right: calc(#{$col-width} * 2 + #{$gutter-width}); } } }


  h1 {
    color: $dark-blue;
    color: $black;
    font-weight: 300;
    margin-top: 0;
    padding-bottom: 1.25rem;
    border-bottom: 2px solid $slate-gray-2; }

  p {
    letter-spacing: 0.02em; }

  h2, h3 {
    color: #92A6B9;
    font-weight: 600;
    margin-top: 3rem; }

  h2 {
    padding-bottom: 1rem;
    border-bottom: 2px solid $slate-gray-2; }

  .row > * {

    @include in-context(medium) {
      width: calc(75% - #{$gutter-width / 2});
      margin-right: auto;
      margin-left: auto; } }

  a, a:link, a:visited, a:hover, a:active {
    color: $dark-blue;
    text-decoration: none;
    font-weight: 500; }

  a:hover {
    color: $black; }

  &.isTinted {
    background: mix(white, $slate-gray-1, 33%);
    @include in-context(medium) {
      padding-bottom: 4rem; }

    h1, h3, h3 {
      border-bottom-color: mix($slate-gray-3, $slate-gray-4, 66%); }

    h2, h3 {
      color: $slate-gray-9; } }

  h2.Login {
    margin-top: 0;
    padding-top: 0; } }
